@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif !important;
}
#root {
  min-height: 100vh;
}
form {
  width: 480px;
}

form > * {
  margin: 10px 0;
}

.userProfileImage {
  max-width: 35px;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
}
video,
img {
  display: block;
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.home_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.home_logo > img {
  width: 150px;
  aspect-ratio: 1;
}

.home_buttons_container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.home_button {
  display: grid;
  place-items: center;
  width: 150px;
  height: 150px;
  padding: 10px;
  background-color: #269fd2;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s;
}

.home_button:hover {
  background-color: #048fca;
}

.home_button > p {
  color: white;
  text-transform: uppercase;
  text-align: center;
}
.logo {
  max-height: 50px;
}
td {
  white-space: nowrap;
}

a {
  color: inherit;
  text-decoration: none;
}
.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #0d6efd transparent #d7e6fd transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.showPassword {
  cursor: pointer;
  position: absolute;
  top: 0.9rem;
  max-height: 100%;
  transition: right 250ms ease;
}

.notValidated {
  right: 1rem;
}
.validated {
  right: 2rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: var(--neutral-color-200); */
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.imageVideo {
  max-width: 100%;
  aspect-ratio: 9/6;
  display: block;
  object-fit: cover;
  border-radius: 10px;
}

.userProfile {
  width: 150px;
  aspect-ratio: 1;
  border-radius: 50%;
}

.userDataImage {
  max-width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 10px;
}
